
import { reactive, ref, onMounted, computed } from 'vue'
import { useStore } from "vuex";
import { Actions } from '@/store/enums/SupplierOrderEnums.ts';
import { Actions as SupplierOrderProductActions } from '@/store/enums/SupplierOrderProductEnums.ts';
import { Actions as SupplierActions } from '@/store/enums/SupplierEnums.ts';
import { Actions as OrderStatusActions } from '@/store/enums/OrderStatusEnums.ts';
import { Actions as UserActions } from '@/store/enums/UserEnums.ts';
import { Actions as CurrencyActions } from '@/store/enums/CurrencyEnums.ts';
import { Actions as ProductActions } from '@/store/enums/ProductEnums.ts';
import { useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Table from '@/components/custom/Table.vue';
import DeleteRecord from '@/components/custom/DeleteRecord.vue';
import SupplierOrderProductAdd from "@/views/orders/supplier/product/SupplierOrderProductAdd.vue";
import SupplierOrderProductEdit from "@/views/orders/supplier/product/SupplierOrderProductEdit.vue";
import numeral from 'numeral'
import { Actions as StoreActions } from "@/store/enums/StoreEnums.ts";
export default {
  name : 'SupplierOrderEdit',
  components : {
    Table,
    DeleteRecord,
    SupplierOrderProductAdd,
    SupplierOrderProductEdit
  },
  setup(){
    const store = useStore();
    // const router = useRouter();
    const route = useRoute();

    const success = ref(false);

    const productActions = computed(() => {
      return SupplierOrderProductActions;
    })

    store.dispatch(StoreActions.ADD_BODY_CLASSNAME,'page-loading')

    onMounted(() => {
      setCurrentPageBreadcrumbs("Supplier Orders",[
        {title : "Supplier Orders",to : "/orders/supplier/list"},
        {title : "Supplier Order  Edit",to : "/orders/supplier/edit/"+route.params.id}
      ]);


      store.dispatch(Actions.GET_SUPPLIER_ORDER,{id : route.params.id});

      store.dispatch(ProductActions.GET_ALL_COMPONENTS,{type : 'component'});
      store.dispatch(CurrencyActions.GET_CURRENCIES);
      store.dispatch(SupplierActions.ALL_SUPPLIERS);
      store.dispatch(OrderStatusActions.GET_ORDER_STATUSES);
      store.dispatch(UserActions.GET_USERS).then(() => {

        store.dispatch(StoreActions.REMOVE_BODY_CLASSNAME,'page-loading')

      })


    });

    const submitButton = ref<HTMLElement | null>(null);

    const errors = reactive({
      value : {}
    });


    const submitForm = () => {

      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.UPDATE_SUPPLIER_ORDER,store.getters.currentSupplierOrder).then((r) => {
        if(r.success){
          errors.value = [];
          success.value = true;
          window.scrollTo(0,0);
          submitButton.value?.removeAttribute("data-kt-indicator");
          // setTimeout(() => {
          //   router.push('/consumers/list')
          // },1000)
        }

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    const columns = [
      {name : 'code', title : 'Product Code', sortable : true, sort : 'asc', searchable : true},
      {name : 'name', title : 'Product Name', sortable : true, sort : null, searchable : true},
      {name : 'units', title : 'Units', sortable : true, sort : null, searchable : true},
      {name : 'currency', title : 'Currency', sortable : true, sort : null, searchable : true},
      {name : 'costPerUnit', title : 'Cost / Unit', sortable : true, sort : null, searchable : false},
      {name : 'total', title : 'Total', sortable : true, sort : null, searchable : true},
      {name : 'transportPerUnit', title : 'Trans / Unit', sortable : true, sort : null, searchable : true},
      {name : 'totalTrans', title : 'Total / Trans', sortable : false, sort : null, searchable : false},
      {name : 'status', title : 'Status', sortable : true, sort : null, searchable : true},
      {name : 'actions', title : 'Edit / Delete',sortable : false, sort : null, searchable : false}
    ];

    return {
      submitButton,
      submitForm,
      errors,
      store,
      success,
      route,
      columns,
      productActions,
      numeral
    }
  }
}
