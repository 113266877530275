<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-sm btn-light-warning font-weight-bold" data-bs-toggle="modal" :data-bs-target="'#exampleModal'+id.uid">
    <i class="fs-1-3 la la-edit"></i>
  </button>
  <!-- Modal -->
  <div class="modal fade" :id="'exampleModal'+id.uid" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Edit Product</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully changed product!
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Product</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.product_id}" v-model="mySupplierOrderProduct.value.product_id" @input="setDefaultInfo">
                <option selected disabled value="null">Select Product</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myAllComponents" :key="key">{{item.name}} / {{item.code}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.product_id">{{errors.value && errors.value.product_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Units</label>
              <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.units}" v-model="mySupplierOrderProduct.value.units">
              <span class="text-danger" v-if="errors.value && errors.value.units">{{errors.value && errors.value.units[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Currencies</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.currency_id}" v-model="mySupplierOrderProduct.value.currency_id">
                <option selected disabled value="null">Select Currency</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myCurrencies" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.currency_id">{{errors.value && errors.value.currency_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Cost / Unit</label>
              <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.costPerUnit}" v-model="mySupplierOrderProduct.value.costPerUnit">
              <span class="text-danger" v-if="errors.value && errors.value.costPerUnit">{{errors.value && errors.value.costPerUnit[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Trans / Unit</label>
              <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.transportPerUnit}" v-model="mySupplierOrderProduct.value.transportPerUnit">
              <span class="text-danger" v-if="errors.value && errors.value.transportPerUnit">{{errors.value && errors.value.transportPerUnit[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :id="'close-modal'+id.uid">Close</button>
          <button
            type="submit"
            :id="'submitButton'+id.uid"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Actions } from "@/store/enums/SupplierOrderProductEnums.ts";
import { useStore } from 'vuex'
import { ref, reactive, onMounted, getCurrentInstance } from 'vue'
 export default {
   name : 'SupplierOrderProductEdit',
   props : {
     product : {
       type : [Array,Object]
     },
   },
   emits: ['input'],
   setup(props, { emit }){
     const store = useStore();

     const id = getCurrentInstance() as any;



     const mySupplierOrderProduct = reactive(JSON.parse(JSON.stringify({ value : props.product})));

     const success = ref(false);

     const errors = reactive({
       value : {}
     });


     const setDefaultInfo = (event) => {
       const product_id = Number(event.target.value)
       store.getters.myAllComponents.forEach((item) => {
         if(item.id == product_id){
           mySupplierOrderProduct.value.units = JSON.parse(JSON.stringify(item.itemsPerCarton))
           mySupplierOrderProduct.value.currency_id = JSON.parse(JSON.stringify(item.currency_id))
           mySupplierOrderProduct.value.costPerUnit = JSON.parse(JSON.stringify(Number(item.costPerCarton / item.itemsPerCarton)))
         }
       });
     }

     onMounted(() => {

       const myModalEl = document.getElementById('exampleModal'+id.uid) as HTMLElement;

       myModalEl.addEventListener('hidden.bs.modal', function () {
         success.value = false;
         mySupplierOrderProduct.value = JSON.parse(JSON.stringify(props.product))
       })

     })


     const submitForm = () => {

       const submitButton = ref<HTMLElement | null>(document.getElementById('submitButton'+id.uid) as HTMLElement);

       if(submitButton.value){
         submitButton.value.setAttribute("data-kt-indicator", "on");
       }

       store.dispatch(Actions.UPDATE_SUPPLIER_ORDER_PRODUCT,mySupplierOrderProduct.value).then(() => {

         errors.value = [];
         success.value = true;

         setTimeout(function(){
           submitButton.value?.removeAttribute("data-kt-indicator");
           const closeModal = document.getElementById('close-modal'+id.uid) as HTMLElement;
           if(closeModal){
             closeModal.click();
           }
           emit('input')
         },1000)

       }).catch((response) => {
         submitButton.value?.removeAttribute("data-kt-indicator");
         errors.value = response.data.errors;
         setTimeout(function(){
           const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
           input.focus();
         },100)

       })
     }

     return {
       id,
       props,
       errors,
       store,
       submitForm,
       mySupplierOrderProduct,
       success
     }
   }
 }
</script>
