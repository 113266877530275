<template>
  <div>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal7">
    Add Product
  </button>
  <!-- Modal -->
  <div class="modal fade" id="exampleModal7" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Add Product</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3" v-show="success">
            <div class="col-12">
              <div class="alert alert-success" role="alert">
                Successfully added product!
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Product</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.product_id}" v-model="formData.product_id" @input="setDefaultInfo">
                <option selected disabled value="null">Select Product</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myAllComponents" :key="key">{{item.name}} / {{item.code}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.product_id">{{errors.value && errors.value.product_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Units</label>
              <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.units}" v-model="formData.units">
              <span class="text-danger" v-if="errors.value && errors.value.units">{{errors.value && errors.value.units[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Currencies</label>
              <select class="form-select" :class="{'is-invalid' : errors.value && errors.value.currency_id}" v-model="formData.currency_id">
                <option selected disabled value="null">Select Currency</option>
                <option :value="item.id" v-for="(item,key) in store.getters.myCurrencies" :key="key">{{item.name}}</option>
              </select>
              <span class="text-danger" v-if="errors.value && errors.value.currency_id">{{errors.value && errors.value.currency_id[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label required fw-bold fs-6">Cost / Unit</label>
              <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.costPerUnit}" v-model="formData.costPerUnit">
              <span class="text-danger" v-if="errors.value && errors.value.costPerUnit">{{errors.value && errors.value.costPerUnit[0]}}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12">
              <label class="col-form-label fw-bold fs-6">Trans / Unit</label>
              <input type="number" step="0.00001" class="form-control" :class="{'is-invalid' : errors.value && errors.value.transportPerUnit}" v-model="formData.transportPerUnit">
              <span class="text-danger" v-if="errors.value && errors.value.transportPerUnit">{{errors.value && errors.value.transportPerUnit[0]}}</span>
            </div>
          </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="close-modal-sop">Close</button>
          <button
            type="submit"
            id="addSOPSubmitBtn"
            class="btn btn-primary fw-bolder"
            @click="submitForm();"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script lang="ts">
import { Actions } from '@/store/enums/SupplierOrderProductEnums.ts';
import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'
  export default {
    name : 'SupplierOrderProductAdd',
    props : ["id"],
    emits: ['input'],
    setup(props, { emit }){
      const store = useStore();

      const success = ref(false)
      const errors = reactive({
        value : {}
      });



      const formData = reactive({
        order_id : props.id,
        product_id : null,
        units : null,
        currency_id : null,
        costPerUnit : null,
        transportPerUnit : null,
      })

      const setDefaultInfo = (event) => {
        const product_id = Number(event.target.value)
        store.getters.myAllComponents.forEach((item) => {
          if(item.id == product_id){
            formData.units = JSON.parse(JSON.stringify(item.itemsPerCarton))
            formData.currency_id = JSON.parse(JSON.stringify(item.currency_id))
            formData.costPerUnit = JSON.parse(JSON.stringify(Number(item.costPerCarton / item.itemsPerCarton)))
          }
        });
      }

      onMounted(() => {

        const myModalEl = document.getElementById('exampleModal7') as any
        myModalEl.addEventListener('hidden.bs.modal', function () {
          success.value = false;
          errors.value = [];
          formData.product_id = null;
          formData.units = null;
          formData.currency_id = null;
          formData.costPerUnit = null;
          formData.transportPerUnit = null;
        })
      })


      const submitForm = () => {
        const submitButton = ref<HTMLElement | null>(document.getElementById('addSOPSubmitBtn') as HTMLElement);

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.ADD_SUPPLIER_ORDER_PRODUCT,formData).then(() => {
          errors.value = [];
          success.value = true;
          setTimeout(function(){

            const closeModal = document.getElementById('close-modal-sop') as HTMLElement;
            closeModal.click();
            emit('input');
            submitButton.value?.removeAttribute("data-kt-indicator");
          },1000)
        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      return {
        props,
        formData,
        errors,
        store,
        submitForm,
        success,
        setDefaultInfo
      }
    }
  }
</script>
