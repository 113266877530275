
import { Actions } from '@/store/enums/SupplierOrderProductEnums.ts';
import { useStore } from 'vuex'
import { ref, reactive, onMounted } from 'vue'
  export default {
    name : 'SupplierOrderProductAdd',
    props : ["id"],
    emits: ['input'],
    setup(props, { emit }){
      const store = useStore();

      const success = ref(false)
      const errors = reactive({
        value : {}
      });



      const formData = reactive({
        order_id : props.id,
        product_id : null,
        units : null,
        currency_id : null,
        costPerUnit : null,
        transportPerUnit : null,
      })

      const setDefaultInfo = (event) => {
        const product_id = Number(event.target.value)
        store.getters.myAllComponents.forEach((item) => {
          if(item.id == product_id){
            formData.units = JSON.parse(JSON.stringify(item.itemsPerCarton))
            formData.currency_id = JSON.parse(JSON.stringify(item.currency_id))
            formData.costPerUnit = JSON.parse(JSON.stringify(Number(item.costPerCarton / item.itemsPerCarton)))
          }
        });
      }

      onMounted(() => {

        const myModalEl = document.getElementById('exampleModal7') as any
        myModalEl.addEventListener('hidden.bs.modal', function () {
          success.value = false;
          errors.value = [];
          formData.product_id = null;
          formData.units = null;
          formData.currency_id = null;
          formData.costPerUnit = null;
          formData.transportPerUnit = null;
        })
      })


      const submitForm = () => {
        const submitButton = ref<HTMLElement | null>(document.getElementById('addSOPSubmitBtn') as HTMLElement);

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.ADD_SUPPLIER_ORDER_PRODUCT,formData).then(() => {
          errors.value = [];
          success.value = true;
          setTimeout(function(){

            const closeModal = document.getElementById('close-modal-sop') as HTMLElement;
            closeModal.click();
            emit('input');
            submitButton.value?.removeAttribute("data-kt-indicator");
          },1000)
        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      return {
        props,
        formData,
        errors,
        store,
        submitForm,
        success,
        setDefaultInfo
      }
    }
  }
